body {
  font-size: 20px;
  --header-height: 72px;
}

label {
  margin-bottom: 0;
}

a,
a:hover {
  text-decoration: none;
}

p, h2 {
  margin: 0;
}

ul {
  margin-bottom: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.toast {
  top: 88px !important;
}